/* src/components/Tenant.css */
.tenant-container {
    display: grid;
    grid-template-columns: 250px 1fr;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .tenant-sidebar {
    background-color: #f4f4f4;
    padding: 10px;
    overflow-y: auto;
  }
  
  .sidebar-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .sidebar-section {
    margin-bottom: 20px;
  }
  
  .sidebar-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .sidebar-section ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-section ul li {
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .tenant-main {
    display: flex;
    flex-direction: column;
  }
  
  .tenant-header {
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
  }
  
  .search-box {
    align-self: flex-end;
  }
  
  .breadcrumb {
    margin-top: 5px;
  }
  
  .page-header {
    font-size: 24px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .tenant-content {
    flex: 1;
    padding: 20px;
    background-color: #fff;
  }
  